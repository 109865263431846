<template>
  <div class="empty-page"></div>
</template>

<script>
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from 'axios';
import { getUserCase } from '@/service/api/userApi';
import { USER_TYPE, getterUserType } from '@/assets/js/usertype';
import { getMySpaceList } from '@/service/api/profileApi';
export default {
  data() {
    return {
      userInfo: {
        userId: null,
        comeFrom: null,
        email: null
      }
    };
  },

  beforeMount() {
    if (this.$store.getters.isLogin) {
      this.$store.commit('setSpaceId', '');
      history.go(1);
    }
  },

  mounted() {
    // 콜백 주소
    const url = window.location.href;
    const uri =
      url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
        ? process.env.VUE_APP_DEV_CALLBACK_URL
        : process.env.VUE_APP_PRO_CALLBACK_URL;

    // 코드
    let code = '';
    let comeFrom = null;
    const cipher = url.indexOf('code=');
    const cipher2 = url.indexOf('state=');
    const state = url.substring(cipher2 + 6);

    // 코드값이 없다면 로그인 페이지로
    if (cipher === -1) {
      router.replace({ name: 'Login' });
    }

    if (cipher2 !== -1) {
      comeFrom = 'naver';
      code = url.substring(cipher + 5, cipher2 - 1);
    } else {
      comeFrom = 'kakao';
      code = url.substring(cipher + 5);
    }

    if (comeFrom === 'kakao') {
      this.kakaoSignUp(uri, code);
    } else {
      this.naverSignUp(code, state);
    }
  },

  methods: {
    naverSignUp(code, state) {
      axios
        .post('/api/social/getNaverOAuthToken', { code, state })
        .then(res => {
          const data = res.data.result;
          if (res.status === 200) {
            VueCookies.set('socialAToken', data.access_token, data.expires_in);
            VueCookies.set('socialRToken', data.refresh_token);

            var deviceToken = '';
            var deviceOs = '';
            if (this.$store.state.isApp === 'aplayzaos') {
              deviceOs = 'aos';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            } else if (this.$store.state.isApp === 'aplayznativeaos') {
              deviceOs = 'aos';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            } else if (this.$store.state.isApp === 'aplayzios') {
              deviceOs = 'ios';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            }
            this.naverLogin(deviceToken, deviceOs);
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response != null && e.response.data.error_code === 'KOE320') {
            router.replace({ name: 'Login' });
          }
        });
    },

    naverLogin(deviceToken, deviceOs) {
      const accessToken = VueCookies.get('socialAToken');
      const comeFrom = VueCookies.get('comeFrom');

      // 쿠키 값이 없으면 email은 null
      let email = null;
      if (comeFrom === 'naver') {
        email = VueCookies.get('email');
      }

      axios
        .post('/api/social/naverCallback', { accessToken, email, deviceToken, deviceOs })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (res.data.result.conversionDate == null) {
              VueCookies.set('userId', result.userId);
              if (result.accessToken != null && result.accessToken !== undefined) {
                // res 값으로 토큰이 있다면 로그인
                VueCookies.set('aToken', result.accessToken, '30d');
                VueCookies.set('rToken', result.refreshToken, '30d');

                this.checkSpace(result.email);
              } else {
                // 없다면 회원가입
                var userInfo = {};
                userInfo.comeFrom = result.comeFrom;
                userInfo.email = result.email;
                router.push({ name: 'SocialAgree', params: { userInfo: userInfo, backStatus: 'Y' } });
              }
            } else {
              // 휴면 계정 로그인 페이지로 이동
              router.push({
                name: 'Login',
                params: {
                  socialEmail: result.email,
                  socialComeFrom: result.comeFrom,
                  socialConversionDate: result.conversionDate
                }
              });
            }
          }
        })
        .catch(e => {
          console.log(e);
          const response = e.response.data;
          if (response.resultCd === '400' && response.resultMsg === '가입된 이메일 정보가 존재합니다.') {
            // 이미 등록되어 있는 아이디와 중복일 경우
            alert(e.response.data.resultMsg);
            router.push({ name: 'Login' });
          } else if (response.resultCd === '400' && response.resultMsg === '탈퇴 후 14일이 지나지 않은 이메일입니다.') {
            // 탈퇴 후 14일이 경과되지 않은 경우
            alert('탈퇴 후 14일이 지나지 않아 재가입이 불가합니다.  \n다른 메일로 가입 부탁드립니다.');
            router.push({ name: 'Login' });
          }
        });
    },

    kakaoSignUp(uri, code) {
      const url = window.location.href;
      const crientId =
        url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
          ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID
          : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;
      const instance = axios.create();
      const makeFormData = params => {
        const searchParams = new URLSearchParams();
        Object.keys(params).forEach(key => {
          searchParams.append(key, params[key]);
        });
        return searchParams;
      };

      instance({
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'https://kauth.kakao.com/oauth/token',
        data: makeFormData({
          grant_type: 'authorization_code',
          client_id: crientId,
          redirect_uri: uri,
          code: code
        })
      })
        .then(res => {
          const data = res.data;
          if (res.status === 200) {
            VueCookies.set('socialAToken', data.access_token, data.expires_in);
            VueCookies.set('socialRToken', data.refresh_token, data.refresh_token_expires_in);

            var deviceToken = '';
            var deviceOs = '';
            if (this.$store.state.isApp === 'aplayzaos') {
              deviceOs = 'aos';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            } else if (this.$store.state.isApp === 'aplayznativeaos') {
              deviceOs = 'aos';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            } else if (this.$store.state.isApp === 'aplayzios') {
              deviceOs = 'ios';
              if (this.$store.state.deviceToken !== '') {
                deviceToken = this.$store.state.deviceToken;
              }
            }

            this.kakaoLogin(deviceToken, deviceOs);
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response != null && e.response.data.error_code === 'KOE320') {
            router.replace({ name: 'Login' });
          }
        });
    },

    kakaoLogin(deviceToken, deviceOs) {
      const accessToken = VueCookies.get('socialAToken');
      const comeFrom = VueCookies.get('comeFrom');

      // 쿠키 값이 없으면 email은 null
      let email = null;
      if (comeFrom === 'kakao') {
        email = VueCookies.get('email');
      }

      axios
        .post('/api/social/kakaoCallback', { accessToken, email, deviceToken, deviceOs })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (res.data.result.conversionDate == null) {
              VueCookies.set('userId', result.userId);
              if (result.accessToken != null && result.accessToken !== undefined) {
                // res 값으로 토큰이 있다면 로그인
                VueCookies.set('aToken', result.accessToken);
                VueCookies.set('rToken', result.refreshToken);

                this.checkSpace(result.email);
              } else {
                // 없다면 회원가입
                var userInfo = {};
                userInfo.comeFrom = result.comeFrom;
                userInfo.email = result.email;
                router.push({ name: 'SocialAgree', params: { userInfo: userInfo, backStatus: 'Y' } });
              }
            } else {
              // 휴면 계정 로그인 페이지로 이동
              router.push({
                name: 'Login',
                params: {
                  socialEmail: result.email,
                  socialComeFrom: result.comeFrom,
                  socialConversionDate: result.conversionDate
                }
              });
            }
          }
        })
        .catch(e => {
          const response = e.response.data;
          if (response.resultCd === '400' && response.resultMsg === '가입된 이메일 정보가 존재합니다.') {
            // 이미 등록되어 있는 아이디와 중복일 경우
            router.push({ name: 'Login' });
          } else if (response.resultCd === '400' && response.resultMsg === '탈퇴 후 14일이 지나지 않은 이메일입니다.') {
            // 탈퇴 후 14일이 경과되지 않은 경우
            alert('탈퇴 후 14일이 지나지 않아 재가입이 불가합니다.  \n다른 메일로 가입 부탁드립니다.');
            router.push({ name: 'Login' });
          }
        });
    },

    checkSpace(email) {
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios.post('/api/user/getLoginUser', { email }, { headers }).then(response => {
        const userId = response.data.result.userId;
        const email = response.data.result.email;
        const comeFrom = response.data.result.comeFrom;
        const userType = response.data.result.userType;
        const signupCouponAt = response.data.result.signupCouponAt;
        const division = response.data.result.division;
        // 쿠키 셋팅

        if (VueCookies.get('autoLogin') === 'S') {
          VueCookies.set('autoLogin', 'Y');
        }
        VueCookies.set('userId', userId);
        VueCookies.set('comeFrom', comeFrom);
        VueCookies.set('email', email);
        VueCookies.set('userType', userType);
        this.$store.commit('setEmail', email);
        this.$store.commit('setSignupCouponAt', signupCouponAt);
        this.$store.commit('setDivision', division);

        const spaceId = VueCookies.get('spaceId');

        if (spaceId != null) {
          axios
            .post('/api/profile/getMySpaceInfoCheck', { spaceId, userId }, { headers })
            .then(res2 => {
              if (res2.data.result) {
                // 이전 접속한 계정과 동일 계정이 접속
                axios
                  .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                  .then(res4 => {
                    if (res4.data.result.spacePayAt === 'Y' || res4.data.result.spacePayAt === 'T') {
                      // 이전 접속한 계정과 동일 계정일 경우 구독여부 체크
                      router.replace({ name: 'Player', params: { spaceId: spaceId } }); // 구독중
                    } else {
                      // 구독 중이 아닐 경우 해당 계정에 spaceId 유무 체크
                      // 쿠키에 저장된 spaceId 삭제
                      VueCookies.remove('spaceId');
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then(res5 => {
                          if (res5.data.resultCd === '0000') {
                            const result = res5.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              this.setUserGb(userId);
                              // 보유 스페이스가 없거나 전부 S인 경우
                              // router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                            } else {
                              getMySpaceList(userId, 10, null).then(res => {
                                const { resultCd, result } = res.data;
                                if (resultCd === '0000') {
                                  const { spaceInfoListInfo } = result;
                                  const { spaceId } = spaceInfoListInfo[spaceInfoListInfo.length - 1];
                                  this.$cookies.set('spaceId', spaceId);
                                  this.$router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                                }
                              });
                            }
                          }
                        })
                        .catch(err5 => {
                          console.log(err5);
                        });
                    }
                  })
                  .catch(err4 => {
                    console.log(err4);
                  });
              } else {
                // 이전 접속한 계정과 다른 계정이 접속
                VueCookies.remove('spaceId');
                axios
                  .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                  .then(response2 => {
                    if (response2.data.result.length > 1) {
                      // 구독중 spaceId가 2개 이상인 경우
                      router.push({ name: 'SpaceChoice' });
                    } else if (response2.data.result.length === 1) {
                      // 구독중 spaceId가 1개인 경우
                      VueCookies.set('spaceId', response2.data.result[0].spaceId);
                      router.push({ name: 'Player' });
                    } else if (response2.data.result.length === 0) {
                      // 구독중인 스페이스가 없는 경우
                      axios
                        .post('/api/profile/checkSpace', { userId }, { headers })
                        .then(response3 => {
                          if (response3.data.resultCd === '0000') {
                            const result = response3.data.result;
                            let sCount = -1;
                            if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                            if (sCount === -1 || sCount === result.length) {
                              this.setUserGb(userId);
                              // 보유 스페이스가 없거나 전부 S인 경우
                              // router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                            } else {
                              getMySpaceList(userId, 10, null).then(res => {
                                const { resultCd, result } = res.data;
                                if (resultCd === '0000') {
                                  const { spaceInfoListInfo } = result;
                                  const { spaceId } = spaceInfoListInfo[spaceInfoListInfo.length - 1];
                                  this.$cookies.set('spaceId', spaceId);
                                  this.$router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                                }
                              });
                            }
                          }
                        })
                        .catch(error3 => {
                          console.log(error3);
                        });
                    }
                  })
                  .catch(error2 => {
                    if (error2) {
                      console.log(error2);
                    }
                  });
              }
            })
            .catch(err2 => {
              console.log(err2);
            });
        } else {
          axios
            .post('/api/profile/subscribeSpaceList', { userId }, { headers })
            .then(response2 => {
              if (response2.data.result.length > 1) {
                // 구독중 spaceId가 2개 이상인 경우
                router.push({ name: 'SpaceChoice' });
              } else if (response2.data.result.length === 1) {
                // 구독중 spaceId가 1개인 경우
                VueCookies.set('spaceId', response2.data.result[0].spaceId);
                router.push({ name: 'Player' });
              } else if (response2.data.result.length === 0) {
                // 구독중인 스페이스가 없는 경우
                axios
                  .post('/api/profile/checkSpace', { userId }, { headers })
                  .then(response3 => {
                    if (response3.data.resultCd === '0000') {
                      const result = response3.data.result;
                      let sCount = -1;
                      if (result.length > 0) sCount = result.filter(e => e === 'S').length;
                      if (sCount === -1 || sCount === result.length) {
                        this.setUserGb(userId);
                        // 보유 스페이스가 없거나 전부 S인 경우
                        // router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                      } else {
                        getMySpaceList(userId, 10, null).then(res => {
                          const { resultCd, result } = res.data;
                          if (resultCd === '0000') {
                            const { spaceInfoListInfo } = result;
                            const { spaceId } = spaceInfoListInfo[spaceInfoListInfo.length - 1];
                            this.$cookies.set('spaceId', spaceId);
                            this.$router.push({ name: 'PlayerView', params: { setIsLoading: true } });
                          }
                        });
                      }
                    }
                  })
                  .catch(error3 => {
                    console.log(error3);
                  });
              }
            })
            .catch(error2 => {
              if (error2) {
                console.log(error2);
              }
            });
        }
      });
    },
    setUserGb(userId = '') {
      getUserCase(userId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const usertype = getterUserType(result.trialYn, result.spaceYn);

          if (usertype === USER_TYPE.FREE_NO_SPACE || usertype === USER_TYPE.END_FREE_NO_SPACE) {
            router.replace({ name: 'PlayerView', params: { setIsLoading: true } });
          } else {
            router.replace({ name: 'SpaceInsert' });
          }
        }
      });
    }
  }
};
</script>

<style>
.empty-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #151515;
}
</style>
